import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, NgForm, AbstractControl, FormGroupDirective, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BusinessPartnerSearchPopupComponent } from '../../../business-partners/business-partner-search-popup/business-partner-search-popup.component';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfessionalServicesService } from '../../professional-services.service';
import { Location } from '@angular/common';
import { ResourceItemService } from '../../resource-item.service';
import { LocationService } from '../../../shared/services/location.service';
import { Profile } from '../../../core/models/profile';
import { RejectionsPopupComponent } from '../rejections-popup/rejections-popup.component';
import { ApprovalComponentComponent } from '../approval-component/approval-component.component';
import { AdminService } from '../../../admin/admin.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../../../shared/services/role.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { TagComponent } from '../../../shared/components/tag/tag.component';
import { Tag } from '../../../shared/components/tags-popup/tags-datasource';
import { TagsService } from '../../../tags/tags.service';
import { TagsPopupComponent } from '../../../shared/components/tags-popup/tags-popup.component';
import { forkJoin } from 'rxjs';
import { MarginAdjustmentsService } from 'src/app/admin/margin-adjustments/margin-adjustments.service';
import { MarginOptionType } from 'src/app/admin/margin-adjustments/models/marginOption';

@Component({
  selector: 'app-parent-estimate-create',
  templateUrl: './parent-estimate-create.component.html',
  styleUrls: ['./parent-estimate-create.component.scss']
})
export class ParentEstimateCreateComponent implements OnInit {

  @ViewChild('form')
  form: NgForm;

  @ViewChild('donwloadCsvLink', { static: true }) private donwloadCsvLink: ElementRef;

  matcher = new MyErrorStateMatcher();
  isDirector: boolean;
  projectName: string;
  resourceFormControl: UntypedFormControl;
  estimateResources: UntypedFormArray;
  randomArray: Array<any>;
  seForm: UntypedFormGroup;
  serviceRoles: Array<any>;
  serviceProjectId: any;
  serviceEstimateId: string;
  states: Array<any>;
  selectedState: string;
  selectedStateControl: UntypedFormControl;
  serviceEstimateOverrideData: any;
  serviceEstimateComponents: Array<any>;
  serviceEstimateChangeOrderComponents: Array<any>;
  isAdmin: boolean;
  isArchitectEngineer: boolean;
  isAvayaArchitectEngineer: boolean;
  isProjectManager: boolean;
  isProjectManagerOfficer: boolean;
  isServicesOperations: boolean;
  isApprover: boolean;
  counties: Array<any>;
  user: AccountInfo | null;
  isFinalApprover: boolean;
  isProcessing: boolean;
  finalApprovers: Array<any>;
  isLocked: boolean;
  isChangeOrder: boolean;
  noComponentsAttachedAndApproved: boolean;
  canSelfApprove: boolean;
  isReportError: boolean;
  isCreatedUser: boolean;
  isScopeRiskEnabled: boolean;
  scopeRiskPrice: any;
  azureAdId: string | undefined;
  @ViewChild('tag') tagComponent: TagComponent;
  tagItems: UntypedFormArray;
  serviceEstimateCurrenyItems: UntypedFormArray;
  tags: Array<Tag>;
  actualTags: Array<Tag>;
  isSelectOpened: boolean;
  isRemoveBtnDisabled: boolean;
  currencies: Array<any>;
  selectedCurrency: any;
  createdDate: string;
  isNotValidGrossMarginPerct: boolean;
  isApprovalPendingArea: boolean;
  serviceEstimateType: number;
  marginOption: any;
  isUpliftDefaulted: boolean;
  isGMPDefaulted: boolean;
  areComponentsAttached: boolean;
  constructor(private fb: UntypedFormBuilder,
    private authService: MsalService,
    private serviceRolesService: ServiceRoleService,
    private router: Router,
    private route: ActivatedRoute,
    private psService: ProfessionalServicesService,
    private location: Location,
    private reourceService: ResourceItemService,
    private locationService: LocationService,
    private adminService: AdminService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private roleService: RoleService,
    private tagsService: TagsService,
    private marginAdjustmentsService: MarginAdjustmentsService) { }

  ngOnInit() {
    this.isReportError = false;
    this.isApprover = false;
    this.isFinalApprover = false;
    this.isChangeOrder = false;
    this.isApprovalPendingArea = true;
    this.tagItems = this.fb.array([]);
    this.serviceEstimateCurrenyItems = this.fb.array([]);
    this.isChangeOrder = this.router.url.includes('ChangeOrder');
    /**
     * TODO: COme back and update
     */
    this.user = this.authService.instance.getActiveAccount();
    var userTokenClaims = this.roleService.idToken();
    if (userTokenClaims) {
      this.azureAdId = userTokenClaims['oid'];
    }
    this.isDirector = false;
    this.roleCheck();
    this.isProcessing = false;
    this.estimateResources = this.fb.array([]);
    this.resourceFormControl = new UntypedFormControl();
    this.selectedStateControl = new UntypedFormControl();
    this.canSelfApprove = false;

    this.canSelfApprove = this.hasRole('PortalSEPostsalesEngineerSA') || this.hasRole('director');

    this.selectedStateControl.valueChanges
      .subscribe(value => {
        this.locationService.getCountiesByStateId(value)
          .subscribe(res => {
            this.counties = res;
          });
      });
    // this.setupRandomArray();
    this.locationService.getStates()
      .subscribe(res => {
        this.states = res.sort((a, b) => (a.abbreviation.length > b.abbreviation.length) ? -1 : 1);

      });
    this.psService.getCurrencies()
      .subscribe((res: any) => {
        this.currencies = res;
      })
    this.route.data.subscribe(data => {
      this.isApprovalPendingArea = data.isApprovalPendingArea;
    })
    this.route.params
      .subscribe(param => {
        this.serviceProjectId = param['projectId'];
        this.serviceEstimateId = param['serviceEstimateId'];
        if (!this.serviceProjectId) {
          this.route.parent?.parent?.params.subscribe(param2 => {
            this.serviceProjectId = param2['id'];
            if (!this.serviceProjectId) {
              this.route.parent?.parent?.parent?.params.subscribe(param2 => {
                this.serviceProjectId = param2['id'];
              });
            }
          });
        }
        this.finalApprovalCheck();

        if (this.serviceEstimateId) {
          this.tagsService.getTags()
            .subscribe(res => {
              this.tags = res;
              this.actualTags = Object.assign([], this.tags);
            });
          this.getMarginOptions();
          this.psService.getSE(this.serviceEstimateId, true)
            .subscribe(res => {
              const data = res;
              this.serviceEstimateType = data.serviceEstimateType;
              this.createdDate = data.createdDate;
              this.isLocked = data.isLocked;
              this.serviceEstimateComponents = data.serviceEstimateComponents;
              this.checkIfComponentsAreAttached();
              data.serviceEstimateTags.forEach((tag: any) => {
                this.addTagItem(tag);
                let tagIndex: number = this.actualTags.findIndex(x => x.id == tag.tagId);
                this.actualTags.splice(tagIndex, 1);
              });
              if (data.serviceEstimateCurrencies.length > 0) {
                data.serviceEstimateCurrencies.forEach((curr: any, index: number) => {
                  this.psService.getCurrencyByConversionRate(curr.currencyConversionRateId).subscribe(res => {
                    curr.currencyCode = res.isoCode;
                    this.addCurrencyItem(curr);
                    this.fetchConversionRate(res.isoCode, 'USD', index);
                  });
                });
              } else {
                this.addCurrencyItem();
              }
              let defaultGMP: any = this.getBPGrossMargin() ? this.getBPGrossMargin() : 0;
              this.serviceEstimateComponents.forEach(sec => {
                sec['canUserApprove'] = false;
                if (sec.businessPartnerItems.length > 0 && !this.isLocked && sec.isAttached) {
                  if ((!sec.grossMarginPercentage || sec.grossMarginPercentage < defaultGMP))
                    this.isNotValidGrossMarginPerct = true;
                  else if (sec.businessPartnerItems.map((x: any) => x.bpGrossMarginPercentage).some((gmp: any) => !gmp || gmp < defaultGMP))
                    this.isNotValidGrossMarginPerct = true;
                }
                this.adminService.getEmployeeByAzureAdId(this.azureAdId)
                  .subscribe(empResult => {
                    sec.serviceRole.employeeServiceRoles.forEach((employeeServiceRole: any) => {
                      let currentId: string = empResult.employeeId;
                      currentId = currentId.toLowerCase();
                      if (currentId === employeeServiceRole.employeeId.toLowerCase()) {
                        sec['canUserApprove'] = true;
                      }
                      this.setReadyForApproval(sec);
                    });
                  });

              });
              this.serviceEstimateChangeOrderComponents = data.changeOrderComponents;
              if (data.estimateResources) {
                data.estimateResources.forEach((element: any) => {
                  this.addResourceItem(element);
                });
              }
              this.isScopeRiskEnabled = data.serviceProject.isScopeRiskEnabled;
              this.scopeRiskPrice = this.isScopeRiskEnabled ? this.calculateScopeRiskPerct(data.estimatedPrice, data.serviceProject.scopeRiskPercentage) : 0;
              this.serviceEstimateOverrideData = {
                'pMHours': data.pmHours,
                'pMHourlyRate': data.pmHourlyRate,
                'bpPmHourlyRate': data.bpPmHourlyRate,
                'bpPmHours': data.bpPmHours,
                'skuHourlyRate': data.skuHourlyRate,
                'skuHours': data.skuHours,
              };



              /**
               * Created to handle the notes section in order to spilt out the created by values
              **/
              let pmOverrideNotesSection: string;
              let pmOverrideNotesCreatedByName: string;
              let bpPmOverrideNotesSection: string;
              let bpPmOverrideNotesCreatedByName: string;
              let skuOverrideNotesSection: string;
              let skuOverrideNotesCreatedByName: string;


              /**
               * Check parsing and spilting to fail gracefully
               */
              const pmOverrideNotes: string = data.pmOverrideNotes;
              if (pmOverrideNotes?.includes('\r\n')) {
                const pmOvverideNotesSpiltArray: Array<string> = pmOverrideNotes?.split(/\r\n/);
                pmOverrideNotesSection = pmOvverideNotesSpiltArray[0];
                let pmOverrideNotesCreatedByNameSection = pmOvverideNotesSpiltArray[1];
                if (pmOverrideNotesCreatedByNameSection.includes('Created By: ')) {
                  pmOverrideNotesCreatedByName = pmOverrideNotesCreatedByNameSection.split('Created By: ')[1];
                }
                else {
                  pmOverrideNotesCreatedByName = '';
                }
              }
              else {
                pmOverrideNotesSection = pmOverrideNotes;
                pmOverrideNotesCreatedByName = '';
              }

              const bpPmOverrideNotes: string = data.bpPmOverrideNotes;
              if (bpPmOverrideNotes?.includes('\r\n')) {
                const bpPmOverrideNotesSpiltArray: Array<string> = bpPmOverrideNotes?.split(/\r\n/);
                bpPmOverrideNotesSection = bpPmOverrideNotesSpiltArray[0];
                bpPmOverrideNotesCreatedByName = bpPmOverrideNotesSpiltArray[1];


                let bpPmOverrideNotesCreatedByNameSection = bpPmOverrideNotesSpiltArray[1];
                if (bpPmOverrideNotesCreatedByNameSection.includes('Created By: ')) {
                  bpPmOverrideNotesCreatedByName = bpPmOverrideNotesCreatedByNameSection.split('Created By: ')[1];
                }
                else {
                  bpPmOverrideNotesCreatedByName = '';
                }


              }
              else {
                bpPmOverrideNotesSection = bpPmOverrideNotes;
                bpPmOverrideNotesCreatedByName = '';
              }

              const skuOverrideNotes: string = data.skuOverrideNotes;
              if (skuOverrideNotes?.includes('\r\n')) {
                const skuOverrideNotesSpiltArray: Array<string> = skuOverrideNotes?.split(/\r\n/);
                skuOverrideNotesSection = skuOverrideNotesSpiltArray[0];
                skuOverrideNotesCreatedByName = skuOverrideNotesSpiltArray[1];


                let skuOverrideNotesCreatedByNameSection = skuOverrideNotesSpiltArray[1];
                if (skuOverrideNotesCreatedByNameSection.includes('Created By: ')) {
                  skuOverrideNotesCreatedByName = skuOverrideNotesCreatedByNameSection.split('Created By: ')[1];
                }
                else {
                  skuOverrideNotesCreatedByName = '';
                }


              }
              else {
                skuOverrideNotesSection = skuOverrideNotes;
                skuOverrideNotesCreatedByName = '';
              }

              this.seForm = this.fb.group({
                'serviceEstimateId': [this.serviceEstimateId],
                'name': [data.name, Validators.required],
                'serviceEstimateType': [data.serviceEstimateType, Validators.required],
                'expirationDate': [data.expirationDate, this.expirationDateValidator(new Date(data.approvedDate), data.isApproved)],
                'milestone': [data.milestone, Validators.pattern("^[0-9]*$")],
                'serviceEstimateStatus': [data.serviceEstimateStatus],
                // 'estimatedWeeks': [data.estimatedWeeks, Validators.required],
                // 'statusMeetingDuration': [data.statusMeetingDuration, Validators.required],
                'srvNumber': [data.srvNumber],
                'isPMOnly': [data.isPMOnly],
                'masterPMOverrideRate': [data.masterPMOverrideRate, Validators.compose([stepValidator(), Validators.min(0)])],
                'pMHourlyRate': [data.pmHourlyRate],
                'pMHours': [data.pmHours],
                'pMPercentage': [data.pmPercentage],
                'bpPmHourlyRate': [data.bpPmHourlyRate],
                'bpPmHours': [data.bpPmHours],
                'bpPmPercentage': [data.bpPmPercentage],
                'skuHourlyRate': [data.skuHourlyRate],
                'skuHours': [data.skuHours],
                'skuPercentage': [data.skuPercentage],
                'pMOverrideHourlyRate': [data.pmOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
                'pMOverrideHours': [data.pmOverrideHours],
                'pMOverridePercentage': [data.pmOverridePercentage, Validators.pattern("^[0-9]*$")],
                'bpPmOverridePercentage': [data.bpPmOverridePercentage, Validators.pattern("^[0-9]*$")],
                'bpPmOverrideHourlyRate': [data.bpPmOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
                'bpPmOverrideHours': [data.bpPmOverrideHours],
                'skuOverridePercentage': [data.skuOverridePercentage, Validators.pattern("^[0-9]*$")],
                'skuOverrideHourlyRate': [data.skuOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
                'skuOverrideHours': [data.skuOverrideHours],
                'isReadyForApproval': [data.isReadyForApproval],
                'margin': [data.margin],
                'createdByEmployeeId': [data.createdByEmployeeId],
                'marginPercentage': [data.marginPercentage],
                'serviceHours': [data.serviceHours],
                'priceAdjustments': [data.priceAdjustments],
                'serviceEstimatePriceAdjustments': [data.serviceEstimatePriceAdjustments],
                'bpSellAmount': [data.bpSellAmount],
                'bpCostAmount': [data.bpCostAmount],
                'businessCost': [data.businessCost],
                'skuCost': [data.skuCost],
                'totalPmCost': [data.totalPmCost],
                'materialCost': [data.materialCost],
                'travelCost': [data.travelCost],
                'servicesCost': [data.servicesCost],
                'estimatedPrice': [data.estimatedPrice],
                'scopeRiskPrice': [this.scopeRiskPrice],
                'updatedEstimatedTotalPrice': [data.estimatedPrice + (data.serviceEstimatePriceAdjustments ? data.serviceEstimatePriceAdjustments : 0) + (this.scopeRiskPrice)],
                'componentNotes': [data.componentNotes],
                'serviceEstimatePriceAdjustmentsNote': [data.serviceEstimatePriceAdjustmentsNote],
                'serviceProjectId': [this.serviceProjectId],
                'estimateResources': this.estimateResources,
                'notes': [data.notes],
                'approvedById': [data.approvedById],
                'finalApproverId': [data.finalApproverId],
                'isMoreApprovalNeeded': [data.isMoreApprovalNeeded],
                'isApproved': [data.isApproved],
                'approvedDate': [data.approvedDate],
                'contractSignedDate': [data.contractSignedDate],
                'isChangeOrder': [this.isChangeOrder],//? needs to be removed
                'pmOverrideNotes': [pmOverrideNotesSection, Validators.maxLength(500)],
                'bppmOverrideNotes': [bpPmOverrideNotesSection, Validators.maxLength(500)],
                'skuOverrideNotes': [skuOverrideNotesSection, Validators.maxLength(500)],
                'pmChangedBy': [pmOverrideNotesCreatedByName],
                'bppmChangedBy': [bpPmOverrideNotesCreatedByName],
                'skuChangedBy': [skuOverrideNotesCreatedByName],
                'version': [data.version],
                serviceEstimateTags: this.tagItems,
                'serviceEstimateCurrencies': this.serviceEstimateCurrenyItems,
                'currencyPrice': [data.currencyPrice]
              });
              this.setupPmDetection();
              this.setUpSEPriceAdjDetection();
              this.setUpSETypeChangeDetection();
              this.clearPmOverride();
              this.checkCreatedUser(data);
              this.onOverrideRateChange();
            });
        } else {
          this.addCurrencyItem();
          this.createdDate = new Date().toDateString();
          this.seForm = this.fb.group({
            'name': [null, Validators.required],
            'serviceEstimateType': [null, Validators.required],
            'expirationDate': [],
            'serviceEstimateStatus': [0],
            // 'estimatedWeeks': [null, Validators.required],
            // 'statusMeetingDuration': [null, Validators.required],
            'milestone': [null, Validators.pattern("^[0-9]*$")],
            'srvNumber': [],
            'isPMOnly': [false],
            'masterPMOverrideRate': [null, Validators.compose([stepValidator(), Validators.min(0)])],
            'pMHourlyRate': [],
            'pMHours': [],
            'pMPercentage': [],
            'bpPmHourlyRate': [],
            'bpPmHours': [],
            'bpPmPercentage': [],
            'skuHourlyRate': [],
            'skuHours': [],
            'skuPercentage': [],
            'pMOverrideHourlyRate': [null, Validators.compose([stepValidator(), Validators.min(0)])],
            'pMOverrideHours': [],
            'pMOverridePercentage': [null, Validators.pattern("^[0-9]*$")],
            'bpPmOverridePercentage': [null, Validators.pattern("^[0-9]*$")],
            'bpPmOverrideHourlyRate': [null, Validators.compose([stepValidator(), Validators.min(0)])],
            'bpPmOverrideHours': [],
            'skuOverridePercentage': [null, Validators.pattern("^[0-9]*$")],
            'skuOverrideHourlyRate': [null, Validators.compose([stepValidator(), Validators.min(0)])],
            'skuOverrideHours': [],
            'isReadyForApproval': [false],
            'margin': [],
            'marginPercentage': [],
            'serviceHours': [],
            'priceAdjustments': [],
            'bpSellAmount': [],
            'bpCostAmount': [],
            'businessCost': [],
            'skuCost': [],
            'estimatedPrice': [],
            'componentNotes': [],
            'totalPmCost': [],
            'materialCost': [],
            'travelCost': [],
            'notes': [],
            'servicesCost': [],
            'serviceProjectId': [this.serviceProjectId],
            'estimateResources': this.estimateResources,
            'isAttached': [],
            'approvedById': [],
            'finalApproverId': [],
            'isMoreApprovalNeeded': [false],
            'isApproved': [],
            'approvedDate': [],
            'contractSignedDate': [],
            'isChangeOrder': [this.isChangeOrder],
            'pmOverrideNotes': [],
            'bppmOverrideNotes': [],
            'skuOverrideNotes': [],
            'pmChangedBy': [],
            'bppmChangedBy': [],
            'version': [],
            serviceEstimateTags: this.tagItems,
            serviceEstimateCurrencies: this.serviceEstimateCurrenyItems
          });

          this.setupPmDetection();
          this.checkCreatedUser();
          this.onOverrideRateChange();
        }

        this.psService.getProject(this.serviceProjectId)
          .subscribe(res => {
            this.projectName = res['projectName'];
          },
            (error) => {
              this.projectName = '';
            });
      });
    this.serviceRolesService.getServiceRoles()
      .subscribe(res => {
        this.serviceRoles = res;

      });
    if (!this.seForm) {

    }
    else {
      this.setupPmDetection();
    }
  }
  calcOverrideHrs() {
    this.calcPMOverride('', null, false);
  }
  calculateScopeRiskPerct(estimatedPrice: any, scopeRiskPerct: any) {
    return estimatedPrice && scopeRiskPerct ? scopeRiskPerct * (estimatedPrice / 100) : 0;
  }
  setupPmDetection() {
    this.seForm.controls['bpPmOverrideHours'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('bpPmOverrideHours', res, false);
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'bpPmOverrideHours');
      });
    this.seForm.controls['pMOverrideHours'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('pMOverrideHours', res, false);
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'pMOverrideHours');
      });
    this.seForm.controls['skuOverrideHours'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('skuOverrideHours', res, false);
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'skuOverrideHours');
      });
    this.seForm.controls['pMOverridePercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('pMOverridePercentage', res, false);
      });
    this.seForm.controls['pMOverrideHourlyRate'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(res => {
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'pMOverrideHourlyRate');
      });
    this.seForm.controls['bpPmOverrideHourlyRate'].valueChanges
      .subscribe(res => {
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'bpPmOverrideHourlyRate');
      });
    this.seForm.controls['skuOverrideHourlyRate'].valueChanges
      .subscribe(res => {
        this.checkOverrideData(isNaN(res) || res == null || res.toString() == "" ? null : +res, 'skuOverrideHourlyRate');
      });
    this.seForm.controls['bpPmOverridePercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('bpPmOverridePercentage', res, false);
      });
    this.seForm.controls['skuOverridePercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcPMOverride('skuOverridePercentage', res, false);
      });
    this.seForm.controls['isPMOnly'].valueChanges
      .subscribe(isPMOnlyValue => {
        const estimatiedWeeksControl = this.seForm.controls['estimatedWeeks'];
        const statusMeetingDurationControl = this.seForm.controls['statusMeetingDuration'];
        if (isPMOnlyValue) {
          if (estimatiedWeeksControl) {
            estimatiedWeeksControl.clearValidators();
            estimatiedWeeksControl.updateValueAndValidity();
          }
          if (statusMeetingDurationControl) {
            statusMeetingDurationControl.clearValidators();
            statusMeetingDurationControl.updateValueAndValidity();
          }
        } else {
          if (estimatiedWeeksControl) {
            estimatiedWeeksControl.setValidators(Validators.required);
            estimatiedWeeksControl.updateValueAndValidity();
          }
          if (statusMeetingDurationControl) {
            statusMeetingDurationControl.setValidators(Validators.required);
            statusMeetingDurationControl.updateValueAndValidity();
          }
        }
      });
  }
  currencyChange(res: any, index: number) {
    this.fetchConversionRate(res.value, 'USD', index);
  }
  fetchConversionRate(targetCurrency: string, currentCurrency: string, index: number) {
    this.psService.getConversionRate(targetCurrency, currentCurrency, this.createdDate)
      .subscribe(res => {
        if (this.serviceEstimateId) {
          this.selectedCurrency = res.currency;
          let estimatedPrice = this.seForm.controls['estimatedPrice'].value;
          let convertedRate = res.conversionRate;
          let convertedCurrencyPrice = estimatedPrice * convertedRate;
          this.seForm.controls['currencyPrice'].setValue(convertedCurrencyPrice);
        }
        let currencyItem = this.getserviceEstimateCurrenyControls().controls[index];
        currencyItem.patchValue({ currencyConversionRateId: res.currencyConversionRateId });
        this.seForm.updateValueAndValidity();
      });
  }
  getserviceEstimateCurrenyControls() {
    return this.seForm.get('serviceEstimateCurrencies') as UntypedFormArray;
  }

  expirationDateValidator(approvedDate: Date, isApproved: boolean ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const expirationDate = control.value;
      // if (!expirationDate || !lastUpdatedDate) {
      //   return null; // No validation if either date is missing
      // }

      const thirtyDaysAfterLastUpdated = new Date(approvedDate);
      thirtyDaysAfterLastUpdated.setDate(thirtyDaysAfterLastUpdated.getDate() + 30);

      if ((expirationDate > thirtyDaysAfterLastUpdated) && isApproved) {
        return { 'expirationDateExceedsThirtyDays': true };
      }

      return null;
    };
  }
  checkOverrideData(res: any, field: any) {
    const pmOverrideNotesControl = this.seForm.controls['pmOverrideNotes'];
    const bppmOverrideNotesControl = this.seForm.controls['bppmOverrideNotes'];
    const skuOverrideNotesControl = this.seForm.controls['skuOverrideNotes'];

    if (field == 'pMOverrideHours') {
      let pmOverrideHourlyRate = this.seForm.controls['pMOverrideHourlyRate'].value;
      if (
        (res < this.serviceEstimateOverrideData.pMHours && res != null) || (pmOverrideHourlyRate < this.serviceEstimateOverrideData.pMHourlyRate && pmOverrideHourlyRate != null && pmOverrideHourlyRate.toString() != "")) {
        this.setNotesValidator(pmOverrideNotesControl);
      } else {
        this.clearNotesValidator(pmOverrideNotesControl);
      }
    } else if (field == 'pMOverrideHourlyRate') {
      let pmOverrideHours = this.seForm.controls['pMOverrideHours'].value;
      if (res < this.serviceEstimateOverrideData.pMHourlyRate && res != null || (pmOverrideHours < this.serviceEstimateOverrideData.pMHours && pmOverrideHours != null && pmOverrideHours.toString() != "")) {
        this.setNotesValidator(pmOverrideNotesControl);
      } else {
        this.clearNotesValidator(pmOverrideNotesControl);
      }
    } else if (field == 'bpPmOverrideHours') {
      let bppmOverrideHourlyRate = this.seForm.controls['bpPmOverrideHourlyRate'].value;
      if (res < this.serviceEstimateOverrideData.bpPmHours && res != null || (bppmOverrideHourlyRate < this.serviceEstimateOverrideData.bpPmHourlyRate && bppmOverrideHourlyRate != null && bppmOverrideHourlyRate.toString() != "")) {
        this.setNotesValidator(bppmOverrideNotesControl);
      } else {
        this.clearNotesValidator(bppmOverrideNotesControl)
      }
    } else if (field == 'bpPmOverrideHourlyRate') {
      let bppmOverrideHours = this.seForm.controls['bpPmOverrideHours'].value;
      if (res < this.serviceEstimateOverrideData.bpPmHourlyRate && res != null || (bppmOverrideHours < this.serviceEstimateOverrideData.bpPmHours && bppmOverrideHours != null && bppmOverrideHours.toString() != "")) {
        this.setNotesValidator(bppmOverrideNotesControl);
      } else {
        this.clearNotesValidator(bppmOverrideNotesControl)
      }
    } else if (field == 'skuOverrideHours') {
      let skuOverrideHourlyRate = this.seForm.controls['skuOverrideHourlyRate'].value;
      if (res < this.serviceEstimateOverrideData.skuHours && res != null || (skuOverrideHourlyRate < this.serviceEstimateOverrideData.skuHourlyRate && skuOverrideHourlyRate != null && skuOverrideHourlyRate.toString() != "")) {
        this.setNotesValidator(skuOverrideNotesControl);
      } else {
        this.clearNotesValidator(skuOverrideNotesControl)
      }
    } else if (field == 'skuOverrideHourlyRate') {
      let skuOverrideHours = this.seForm.controls['skuOverrideHours'].value;
      if (res < this.serviceEstimateOverrideData.skuHourlyRate && res != null || (skuOverrideHours < this.serviceEstimateOverrideData.skuHours && skuOverrideHours != null && skuOverrideHours.toString() != "")) {
        this.setNotesValidator(skuOverrideNotesControl);
      } else {
        this.clearNotesValidator(skuOverrideNotesControl)
      }
    }
  }
  setNotesValidator(control: AbstractControl) {
    control.setValidators([Validators.required, Validators.maxLength(500)]);
    control.updateValueAndValidity();
  }
  clearNotesValidator(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
  }
  setUpSEPriceAdjDetection() {
    this.seForm.controls['serviceEstimatePriceAdjustments'].valueChanges
      .subscribe(res => {
        const sePriceAdjustmentNotesControl = this.seForm.controls['serviceEstimatePriceAdjustmentsNote'];
        const sePriceAdjustmentsVal = this.seForm.controls['serviceEstimatePriceAdjustments'].value;
        const estimatedPriceVal = this.seForm.controls['estimatedPrice'].value;
        this.seForm.controls['updatedEstimatedTotalPrice'].setValue(sePriceAdjustmentsVal + estimatedPriceVal + this.scopeRiskPrice);
        if (res) {
          sePriceAdjustmentNotesControl.setValidators(Validators.required);
        } else {
          sePriceAdjustmentNotesControl.clearValidators();
        }
        sePriceAdjustmentNotesControl.updateValueAndValidity();
      });
  }
  setUpSETypeChangeDetection() {
    this.seForm.controls['serviceEstimateType'].valueChanges
      .subscribe(value => {
        this.serviceEstimateType = value;
      });
  }

  updateCounties(stateId?: string) {
    this.locationService.getCountiesByStateId(this.selectedState)
      .subscribe(res => {
        this.counties = res;

      });
  }
  addResourceItem(resourceItem: any) {
    const groupItem: UntypedFormGroup = this.setupResourceItemFormGroup(resourceItem);
    this.estimateResources.push(groupItem);
    this.resourceFormControl.setValue(this.estimateResources.length);
  }
  removeResourceItem(i: any) {
    const id = this.estimateResources.controls[i].value['id'];
    if (id) {
      this.reourceService.deleteResouirceItems(id)
        .subscribe(res => {
          this.estimateResources.removeAt(i);
          this.resourceFormControl.setValue(this.estimateResources.length);

        });
    } else {
      this.estimateResources.removeAt(i);
      this.resourceFormControl.setValue(this.estimateResources.length);
    }
  }
  setupResourceItemFormGroup(resourceItem?: any) {
    let group: UntypedFormGroup;
    if (resourceItem) {
      group = this.fb.group({
        id: resourceItem.id ? resourceItem.id : null,
        serviceRoleId: resourceItem.serviceRoleId ? resourceItem.serviceRoleId : null,
        payRate: resourceItem.payRate ? resourceItem.payRate : null,
        overrideRate: resourceItem.overrideRate ? resourceItem.overrideRate : null,
        serviceEstimateId: resourceItem.serviceEstimateId ? resourceItem.serviceEstimateId : null,
        estimatedWeeks: [resourceItem.estimatedWeeks ? resourceItem.estimatedWeeks : null, Validators.required],
        estimatedDuration: [resourceItem.estimatedDuration ? resourceItem.estimatedDuration : null, Validators.required]
      });
    } else {
      group = this.fb.group({
        serviceRoleId: [],
        payRate: [],
        overrideRate: [],
        estimatedWeeks: [null, Validators.required],
        estimatedDuration: [null, Validators.required]
      });
    }

    group.controls['estimatedWeeks'].markAllAsTouched();
    group.controls['estimatedDuration'].markAllAsTouched();

    group.controls['serviceRoleId'].valueChanges
      .subscribe(value => {
        const selectedRole = this.serviceRoles.filter(v => {
          return v.serviceRoleId === value;
        })[0];
        group.controls['payRate'].setValue(selectedRole.serviceRoleHourlyRate);
      });
    return group;
  }
  roleCheck() {
    let updatedRoles: string[];
    let userTokenClaims = this.roleService.idToken();
    if (userTokenClaims) {
      if (typeof userTokenClaims['roles'] === 'string') {
        updatedRoles = [
          userTokenClaims['roles']
        ];
      } else {
        updatedRoles = userTokenClaims['roles'];
      }
      updatedRoles.forEach(r => {
        const role = r.toLocaleLowerCase();
        if (role.includes('director')) {
          this.isDirector = true;
          this.isApprover = true;
        }
        if (role.includes('portaladmin')) {
          this.isDirector = true;
          this.isApprover = true;
        }
        if (role.includes('projects.approver')) {
          this.isDirector = true;
          this.isApprover = true;
        }
        if (role.includes('portalsepresalesarchitectsa')) {
          this.isApprover = true;
        }
        if (role.includes('portalseprojectmanagerofficer')) {
          this.isProjectManagerOfficer = true;
        }
        if (role.includes('portaladmin')) {
          this.isProjectManagerOfficer = true;
        }
        this.isAdmin = this.hasRole('Admin');
        this.isArchitectEngineer = this.hasRole('Architect');

        this.isAvayaArchitectEngineer = this.hasRole('AvayaArchitect');
        this.isProjectManager = this.hasRole('PortalSEProjectManager');
        this.isServicesOperations = this.hasRole('ServicesOperations');
      });
    } else {

    }

  }
  setupRandomArray() {
    this.randomArray = [];
    this.resourceFormControl.valueChanges.subscribe(res => {
      if (res) {
        if (res !== 0) {
          if (res !== this.randomArray.length) {
            if (res > this.randomArray.length) {
              for (let i = 0; i < res; i++) {
                if (this.randomArray.length < res) {
                  this.randomArray.push(i);
                } else if (this.randomArray.length > res) {
                  this.randomArray.splice(this.randomArray.length - 1);
                }
              }
            } else {
              this.randomArray.forEach(item => {
                this.randomArray.splice(this.randomArray.length - 1);
              });
            }
          }
        }
      } else {
        this.randomArray = [];
      }
    });
  }
  addResource() {
    this.randomArray.push(1);
    this.resourceFormControl.setValue(this.randomArray.length);
  }
  removeResource(index: number) {
    this.randomArray.splice(index, 1);
    this.resourceFormControl.setValue(this.randomArray.length);
  }

  clearPmOverride() {
    if (this.serviceEstimateComponents.filter(x => x.isAttached && x.businessPartnerItems?.length > 0)?.length == 0) {
      this.seForm.patchValue({
        bpPmOverridePercentage: null,
        bpPmOverrideHours: null,
        bpPmOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
    if (this.serviceEstimateComponents.filter(x => x.isAttached && x.skuItems?.length > 0)?.length == 0) {
      this.seForm.patchValue({
        skuOverridePercentage: null,
        skuOverrideHours: null,
        skuOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
    if (this.serviceEstimateComponents.filter(x => x.isAttached)?.length == 0) {
      this.seForm.patchValue({
        pMOverridePercentage: null,
        pMOverrideHours: null,
        pMOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
  }

  calcPMOverride(fieldName: string, formValue?: any, isbpPmOverrideHourlyRate?: boolean, isSkuOverrideHourlyRate?: boolean) {
    if (this.serviceEstimateComponents.filter(x => x.isAttached && x.businessPartnerItems?.length > 0)?.length == 0) {
      this.seForm.patchValue({
        bpPmOverridePercentage: null,
        bpPmOverrideHours: null,
        bpPmOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
    if (this.serviceEstimateComponents.filter(x => x.isAttached && x.skuItems?.length > 0)?.length == 0) {
      this.seForm.patchValue({
        skuOverridePercentage: null,
        skuOverrideHours: null,
        skuOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
    if (this.serviceEstimateComponents.filter(x => x.isAttached)?.length == 0) {
      this.seForm.patchValue({
        pMOverridePercentage: null,
        pMOverrideHours: null,
        pMOverrideHourlyRate: null
      }, { emitEvent: false });
      this.seForm.updateValueAndValidity();
    }
    else {
      let bpPmOverrideHourlyRate = this.seForm.value.bpPmOverrideHourlyRate;
      let skuOverrideHourlyRate = this.seForm.value.skuOverrideHourlyRate;
      let overrideField: any = { bpPmOverideHourlyRate: !(isNaN(bpPmOverrideHourlyRate) || bpPmOverrideHourlyRate == null || bpPmOverrideHourlyRate.toString() == "") ? this.seForm.value.bpPmOverrideHourlyRate : null, skuOverrideHourlyRate: !(isNaN(skuOverrideHourlyRate) || skuOverrideHourlyRate == null || skuOverrideHourlyRate.toString() == "") ? this.seForm.value.skuOverrideHourlyRate : null };

      let value;
      value = isbpPmOverrideHourlyRate ? this.seForm.controls['bpPmOverrideHours'].value : isSkuOverrideHourlyRate ? this.seForm.controls['skuOverrideHours'].value : formValue != null ? +formValue : null;
      if (fieldName == "") {
        let controls = ['pMOverridePercentage', 'bpPmOverridePercentage', 'skuOverridePercentage'];
        controls.forEach((control) => {
          let value = this.seForm.controls[control].value;
          if ((!(isNaN(value) || value == null || value.toString() == "")) && (control == "" || this.seForm.controls[control].valid)) {
            overrideField[control] = value;
          }
        });
      } else if ((!(isNaN(value) || value == null || value.toString() == "")) && this.seForm.controls[fieldName].valid) {
        overrideField[fieldName] = value;
      }
      if (Object.keys(overrideField).length != 0) {
        this.setOverrideValues(fieldName, overrideField)
      }
    }
  }

  setOverrideValues(fieldName: string, overrideField: any) {
    this.psService.calcPMOverride(this.serviceEstimateId, overrideField)
      .subscribe((res: any) => {
        if (fieldName == 'pMOverrideHours') {
          this.seForm.controls['pMOverridePercentage'].patchValue(res.pmOverridePercentage, { emitEvent: false });
        } else if (fieldName == 'pMOverridePercentage' || fieldName == '') {
          this.seForm.controls['pMOverrideHours'].patchValue(res.pmOverrideHours, { emitEvent: false });
        } if (fieldName == 'bpPmOverrideHours') {
          this.seForm.controls['bpPmOverridePercentage'].patchValue(res.bpPmOverridePercentage, { emitEvent: false });
        } else if (fieldName == 'bpPmOverridePercentage' || fieldName == '') {
          this.seForm.controls['bpPmOverrideHours'].patchValue(res.bpPmOverrideHours, { emitEvent: false });
        } if (fieldName == 'skuOverrideHours') {
          this.seForm.controls['skuOverridePercentage'].patchValue(res.skuOverridePercentage, { emitEvent: false });
        } else if (fieldName == 'skuOverridePercentage' || fieldName == '') {
          this.seForm.controls['skuOverrideHours'].patchValue(res.skuOverrideHours, { emitEvent: false });
        }
        this.seForm.updateValueAndValidity();
      });
  }

  sendForFinalApproval() {
    if (this.seForm.valid) {
      let formValue = this.seForm.value;
      formValue.serviceEstimateCurrencies = formValue.serviceEstimateCurrencies.filter((x: any) => x.currencyConversionRateId != null);
      this.psService.sendSeForApproval(formValue)
        .subscribe(res => {
          this.navigateToProjDetails();

          this.fetchSeSecData();
          this.finishedProcessing();
        },
          (error) => {
            var data = error;
            this.noComponentsAttachedAndApproved = !data['hasApprovedComponents'];

          });
    }
  }
  submitServiceEstimate() {
    if (this.seForm.valid) {
      let formValue = this.seForm.value;
      formValue.serviceEstimateCurrencies = formValue.serviceEstimateCurrencies.filter((x: any) => x.currencyConversionRateId != null);
      if (this.serviceEstimateId) {
        this.psService.updateSE(formValue)
          .subscribe(res => {
            this.navigateToProjDetails();

            this.fetchSeSecData();
            this.finishedProcessing();

          }, error => {
          });
      } else {
        this.psService.createSE(formValue)
          .subscribe(res => {
            this.navigateToProjDetails();
            this.finishedProcessing();

          }, error => {
          });
      }
    } else {

      this.seForm.markAllAsTouched();
    }
  }
  attachServiceEstimateComponent(id: string) {
    this.processingAttachDetach();
    this.psService.attachServiceEstimateComponent(id)
      .subscribe(res => {
        var masterPMOverrideRate = this.seForm.controls['masterPMOverrideRate'].value;
        this.fetchSeSecData();
        this.seForm.controls['masterPMOverrideRate'].setValue(masterPMOverrideRate);
        this.finishedProcessing();
      });
  }
  detachServiceEstimateComponent(id: string) {
    this.processingAttachDetach();
    this.psService.detachServiceEstimateComponent(id)
      .pipe(tap(res => this.processingAttachDetach()))
      .subscribe(res => {
        var masterPMOverrideRate = this.seForm.controls['masterPMOverrideRate'].value;
        this.fetchSeSecData();
        this.seForm.controls['masterPMOverrideRate'].setValue(masterPMOverrideRate);
        this.finishedProcessing();
      });
  }

  refreshChangeOrders() {
    this.psService.getSEChangeOrdersBySeId(this.serviceEstimateId)
      .subscribe(res => {
        this.serviceEstimateChangeOrderComponents = res;
      });
  }
  refreshServiceEstimateCompoentsn(res: any) {
    this.serviceEstimateComponents = res;
    this.checkIfComponentsAreAttached();
    let defaultGMP: any = this.getBPGrossMargin() ? this.getBPGrossMargin() : 0;
    this.serviceEstimateComponents.forEach(sec => {
      sec['canUserApprove'] = false;
      if (sec.businessPartnerItems.length > 0 && !this.isLocked && sec.isAttached) {
        if ((!sec.grossMarginPercentage || sec.grossMarginPercentage < defaultGMP))
          this.isNotValidGrossMarginPerct = true;
        else if (sec.businessPartnerItems.map((x: any) => x.bpGrossMarginPercentage).some((gmp: any) => !gmp || gmp < defaultGMP))
          this.isNotValidGrossMarginPerct = true;
      }
      this.adminService.getEmployeeByAzureAdId(this.azureAdId)
        .subscribe(empResult => {
          sec.serviceRole.employeeServiceRoles.forEach((employeeServiceRole: any) => {
            if (empResult.employeeId == employeeServiceRole.employeeId) {
              sec['canUserApprove'] = true;
            }
            this.setReadyForApproval(sec);
          });
        });
    });
  }

  checkIfComponentsAreAttached() {
    this.areComponentsAttached = this.serviceEstimateComponents?.filter(x => x.isAttached)?.length > 0;
    if(!this.areComponentsAttached){
      this.seForm?.controls['masterPMOverrideRate'].setValue(null);
    }
  }

  fetchSeSecData() {
    this.isNotValidGrossMarginPerct = false;
    let se = this.psService.getSE(this.serviceEstimateId, true);
    let sec = this.psService.getSEComponentsBySeId(this.serviceEstimateId);
    forkJoin([se, sec])
      .subscribe(results => {
        this.updateSeForm(results[0]);
        this.refreshServiceEstimateCompoentsn(results[1]);
        this.clearPmOverride();
      });
  }

  refreshServiceEstimateComponents() {
    this.isNotValidGrossMarginPerct = false;
    this.psService.getSEComponentsBySeId(this.serviceEstimateId)
      .subscribe(res => {
        this.refreshServiceEstimateCompoentsn(res);
        this.clearPmOverride();
      });
  }

  updateSeForm(res: any) {
    const data = res;
    this.serviceEstimateType = res.serviceEstimateType;
    this.isScopeRiskEnabled = data.serviceProject.isScopeRiskEnabled;
    this.scopeRiskPrice = this.isScopeRiskEnabled ? this.calculateScopeRiskPerct(data.estimatedPrice, data.serviceProject.scopeRiskPercentage) : 0;
    this.isLocked = data.isLocked;
    var masterPMOverrideRate = this.seForm.controls['masterPMOverrideRate']?.value;
    this.seForm = this.fb.group({
      'serviceEstimateId': [this.serviceEstimateId],
      'name': [data.name, Validators.required],
      'serviceEstimateType': [data.serviceEstimateType, Validators.required],
      'expirationDate': [data.expirationDate],
      'milestone': [data.milestone, Validators.pattern("^[0-9]*$")],
      'serviceEstimateStatus': [data.serviceEstimateStatus],
      // 'estimatedWeeks': [data.estimatedWeeks, Validators.required],
      // 'statusMeetingDuration': [data.statusMeetingDuration, Validators.required],
      'srvNumber': [data.srvNumber],
      'isPMOnly': [data.isPMOnly],
      'masterPMOverrideRate': [masterPMOverrideRate, Validators.compose([stepValidator(), Validators.min(0)])],
      'pMHourlyRate': [data.pmHourlyRate],
      'pMHours': [data.pmHours],
      'pMPercentage': [data.pmPercentage],
      'bpPmHourlyRate': [data.bpPmHourlyRate],
      'bpPmHours': [data.bpPmHours],
      'bpPmPercentage': [data.bpPmPercentage],
      'skuHourlyRate': [data.skuHourlyRate],
      'skuHours': [data.skuHours],
      'skuPercentage': [data.skuPercentage],
      'pMOverrideHourlyRate': [data.pmHourlyRate ? masterPMOverrideRate : data.pmOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
      'pMOverrideHours': [data.pmOverrideHours],
      'pMOverridePercentage': [data.pmOverridePercentage, Validators.pattern("^[0-9]*$")],
      'bpPmOverridePercentage': [data.bpPmOverridePercentage ? data.bpPmOverridePercentage : null, Validators.pattern("^[0-9]*$")],
      'bpPmOverrideHourlyRate': [data.bpPmHourlyRate ? masterPMOverrideRate : data.bppmOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
      'bpPmOverrideHours': [data.bpPmOverrideHours],
      'skuOverridePercentage': [data.skuOverridePercentage ? data.skuOverridePercentage : null, Validators.pattern("^[0-9]*$")],
      'skuOverrideHourlyRate': [data.skuHourlyRate ? masterPMOverrideRate : data.skuOverrideHourlyRate, Validators.compose([stepValidator(), Validators.min(0)])],
      'skuOverrideHours': [data.skuOverrideHours],
      'isReadyForApproval': [data.isReadyForApproval],
      'margin': [data.margin],
      'marginPercentage': [data.marginPercentage],
      'createdByEmployeeId': [data.createdByEmployeeId],
      'serviceHours': [data.serviceHours],
      'priceAdjustments': [data.priceAdjustments],
      'serviceEstimatePriceAdjustments': [data.serviceEstimatePriceAdjustments],
      'bpSellAmount': [data.bpSellAmount],
      'bpCostAmount': [data.bpCostAmount],
      'businessCost': [data.businessCost],
      'skuCost': [data.skuCost],
      'totalPmCost': [data.totalPmCost],
      'materialCost': [data.materialCost],
      'travelCost': [data.travelCost],
      'servicesCost': [data.servicesCost],
      'estimatedPrice': [data.estimatedPrice],
      'scopeRiskPrice': [this.scopeRiskPrice],
      'updatedEstimatedTotalPrice': [data.estimatedPrice + (data.serviceEstimatePriceAdjustments ? data.serviceEstimatePriceAdjustments : 0) + (this.scopeRiskPrice)],
      'componentNotes': [data.componentNotes],
      'serviceEstimatePriceAdjustmentsNote': [data.serviceEstimatePriceAdjustmentsNote],
      'serviceProjectId': [this.serviceProjectId],
      'estimateResources': this.estimateResources,
      'approvedById': [data.approvedById],
      'finalApproverId': [data.finalApproverId],
      'isMoreApprovalNeeded': [data.isMoreApprovalNeeded],
      'isApproved': [data.isApproved],
      'approvedDate': [data.approvedDate],
      'contractSignedDate': [data.contractSignedDate],
      'isChangeOrder': [this.isChangeOrder],
      'pmOverrideNotes': [data.pmOverrideNotes?.split(/\r\n/)[0]],
      'bppmOverrideNotes': [data.bpPmOverrideNotes?.split(/\r\n/)[0]],
      'skuOverrideNotes': [data.skumOverrideNotes?.split(/\r\n/)[0]],
      'pmChangedBy': [data.pmOverrideNotes?.split(/\r\n/)[1]?.split('Created By: ')[1]],
      'bppmChangedBy': [data.bpPmOverrideNotes?.split(/\r\n/)[1]?.split('Created By: ')[1]],
      'skuChangedBy': [data.skuOverrideNotes?.split(/\r\n/)[1]?.split('Created By: ')[1]],
      'version': [data.version],
      serviceEstimateTags: this.tagItems,
      'serviceEstimateCurrencies': this.serviceEstimateCurrenyItems,
      'currencyPrice': [data.currencyPrice]
    });
    this.serviceEstimateOverrideData = {
      'pMHours': data.pmHours,
      'pMHourlyRate': data.pmHourlyRate,
      'bpPmHourlyRate': data.bpPmHourlyRate,
      'bpPmHours': data.bpPmHours,
      'skuHourlyRate': data.skuHourlyRate,
      'skuHours': data.skuHours,
    };
    this.setupPmDetection();
    this.onOverrideRateChange();
    this.setUpSETypeChangeDetection();
    this.updateCurrencyPrice();
    this.seForm.updateValueAndValidity();
  }
  updateCurrencyPrice() {
    this.getserviceEstimateCurrenyControls().controls.forEach((curr: any, index: number) => {
      if (this.selectedCurrency)
        this.fetchConversionRate(this.selectedCurrency.isoCode, 'USD', index);
    })
  }
  checkCreatedUser(data?: any) {
    let employeeId;
    if (this.serviceEstimateId) {
      this.adminService.getEmployeeByAzureAdId(this.azureAdId)
        .subscribe(empResult => {
          employeeId = empResult.employeeId;
          this.isCreatedUser = data.createdByEmployeeId == employeeId;
        });
    }
    else
      this.isCreatedUser = true;
  }
  public canDeactivate(): boolean {
    const state = this.form?.submitted || !this.form?.dirty;
    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }

  onOverrideRateChange() {
    this.seForm.controls['masterPMOverrideRate'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((componentOverrideRate) => {
          
        // Update PM Override Hourly Rate if PM Hourly Rate exists
        if (this.seForm.get('pMHourlyRate')?.value != null) {
          this.seForm.get('pMOverrideHourlyRate')?.setValue(componentOverrideRate);
        }
  
        // Update BP PM Override Hourly Rate if BP PM Hourly Rate exists
        if (this.seForm.get('bpPmHourlyRate')?.value != null) {
          this.seForm.get('bpPmOverrideHourlyRate')?.setValue(componentOverrideRate);
        }
  
        // Update SKU Override Hourly Rate if SKU Hourly Rate exists
        if (this.seForm.get('skuHourlyRate')?.value != null) {
          this.seForm.get('skuOverrideHourlyRate')?.setValue(componentOverrideRate);
        }
  
        this.seForm.updateValueAndValidity();
      });
  }
  

  processingAttachDetach() {
    this.isProcessing = true;
  }
  finishedProcessing() {
    this.isProcessing = false;
  }
  getMarginOptions(){
    this.marginAdjustmentsService.getMarginOptions()
      .subscribe((res: any) => {
        this.marginOption = res;
        this.isUpliftDefaulted = this.marginOption.marginOptionType == MarginOptionType.Uplift;
        this.isGMPDefaulted = this.marginOption.marginOptionType == MarginOptionType.GrossMargin;
      });
  }
  getBPGrossMargin(){
    return this.isGMPDefaulted ? Number.parseFloat(this.marginOption.grossMarginPercentage) : null;
  }
  getBPUpliftMargin(){
    return this.isUpliftDefaulted? Number.parseFloat(this.marginOption.upliftPercentage) : null;
  }
  getMarginBpItems(comp: any){
    let reducedMarginBPItems: any;
    if(this.isUpliftDefaulted){
      reducedMarginBPItems = comp.businessPartnerItems?.filter((pi: any) => pi.upliftPercentage < Number.parseFloat(this.marginOption.upliftPercentage));
    }else if(this.isGMPDefaulted){
      reducedMarginBPItems = comp.businessPartnerItems?.filter((pi: any) => pi.bpGrossMarginPercentage < Number.parseFloat(this.marginOption.grossMarginPercentage));
    }
    return reducedMarginBPItems;
  }
  displayApprovalIcon(comp: any) {
    let reducedRatePhaseItems = comp.phaseItems?.filter((pi: any) => pi.serviceRatePerQuanity > pi.overrideRate);
    let reducedMarginBPItems = this.getMarginBpItems(comp);

    if (((reducedMarginBPItems?.length > 0 || reducedRatePhaseItems?.length > 0) && comp.canUserApprove) || (reducedMarginBPItems?.length == 0 && reducedRatePhaseItems?.length == 0 && (this.isDirector || comp.canUserApprove || this.canSelfApprove))) {
      return false;
    } else
      return comp.createdBy.azureAdId == this.azureAdId;
  }
  setReadyForApproval(sec: any) {
    let reducedRatePhaseItems = sec.phaseItems?.filter((pi: any) => pi.serviceRatePerQuanity > pi.overrideRate);
    let reducedMarginBPItems = this.getMarginBpItems(sec);

    if (!sec.isApproved && !sec.isReadyForApproval && sec.isAttached && (((reducedMarginBPItems?.length > 0 || reducedRatePhaseItems?.length > 0) && sec.canUserApprove) || (reducedMarginBPItems?.length == 0 && reducedRatePhaseItems?.length == 0 && (this.isDirector || sec.canUserApprove || this.canSelfApprove))))
      sec.isReadyForApproval = true;
  }
  canBeApproved(comp: any) {
    let reducedRatePhaseItems = comp.phaseItems?.filter((pi: any) => pi.serviceRatePerQuanity > pi.overrideRate);
    let reducedMarginBPItems = this.getMarginBpItems(comp);

    return (reducedMarginBPItems?.length == 0 && reducedRatePhaseItems?.length == 0 && this.canSelfApprove) && comp.isReadyForApproval;
  }
  getTagName(id: string) {
    return this.tags.find(tag => tag.id == id)?.name;
  }
  addTagItem(tagItem: any, isNewTag: boolean = false) {
    let item: UntypedFormGroup;
    if (!isNewTag) {
      item = this.fb.group({
        'id': [tagItem.id],
        'tagId': [tagItem.tagId],
        'serviceEstimateId': [this.serviceEstimateId],
        'CreatedByEmployeeId': [tagItem.createdByEmployeeId],
        'CreatedDate': [tagItem.createdDate]
      });
    } else {
      item = this.fb.group({
        'tagId': [tagItem.id],
        'serviceEstimateId': [this.serviceEstimateId]
      });
    }
    this.tagItems.push(item);
  }
  removeTagItem(index: number, event: Event) {
    this.isRemoveBtnDisabled = true;
    let tag = this.tagItems.at(index).value;
    event.stopPropagation();
    if (tag.id) {
      this.tagsService.removeSETag(tag.id)
        .subscribe(res => {
          this.tagItems.removeAt(index);
          this.isRemoveBtnDisabled = false;
        });
    } else {
      this.tagItems.removeAt(index);
      this.isRemoveBtnDisabled = false;
    }
    let tagToInsertIndex: number = this.tags.findIndex(t => t.id == tag.tagId);
    this.actualTags.push(this.tags[tagToInsertIndex]);
    this.tagComponent.setTagControlValue();
  }
  addCurrencyItem(currencyItem?: any) {
    let item: UntypedFormGroup;
    if (currencyItem) {
      item = this.fb.group({
        'id': [currencyItem.id],
        'currencyConversionRateId': [currencyItem.currencyConversionRateId],
        'serviceEstimateId': [this.serviceEstimateId],
        'currencyCode': [currencyItem.currencyCode]
      });
    } else if (this.serviceEstimateId) {
      item = this.fb.group({
        'currencyConversionRateId': [null],
        'serviceEstimateId': [this.serviceEstimateId],
        'currencyCode': [null]
      });
    } else {
      item = this.fb.group({
        'currencyConversionRateId': [null],
        'currencyCode': [null]
      });
    }
    this.serviceEstimateCurrenyItems.push(item);
  }
  toggleSelect() {
    if (!this.isSelectOpened) {
      this.tagComponent.select.openPanel();
    }
    this.isSelectOpened = !this.isSelectOpened;
  }
  toggleSelectOpened() {
    this.isSelectOpened = false;
  }
  tagSelected(tag: Tag) {
    this.addTagItem(tag, true);
    this.toggleSelectOpened();
  }

  approveComponent(comp: any) {
    const dialogRef = this.dialog.open(ApprovalComponentComponent, {
      width: '475px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(res => {
      if ((res != null && res != undefined && res != false) || res === '') {
        comp.isApproved = true;
        const rejection = {
          'serviceEstimateComponentId': comp.id,
          'notes': res
        };
        comp['approvals'] = [];
        comp['approvals'].push(rejection);
        comp['serviceEstimateComponentUrl'] = window.location.href;
        this.psService.approveServiceEstimateComponent(comp)
          .subscribe(res => {
            this.fetchSeSecData();
          },
            (error) => {
              comp.isApproved = false;
            });
      } else {
      }

    });
  }

  rejectComponent(comp: any) {
    const dialogRef = this.dialog.open(RejectionsPopupComponent, {
      width: '475px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != null && res != undefined && res != false || res === '') {
        comp.isRejected = true;
        const rejection = {
          'serviceEstimateComponentId': comp.id,
          'notes': res
        };
        comp['rejections'] = [];
        comp['rejections'].push(rejection);
        comp['serviceEstimateComponentUrl'] = window.location.href;
        this.psService.rejectServiceEstimateComponent(comp)
          .subscribe(res => {
            this.fetchSeSecData();
          });
      } else {
      }
    });
  }
  openTagPopup(tagId: string) {
    const popup = this.dialog.open(TagsPopupComponent, {
      height: '60%',
      minWidth: '60%',
      panelClass: 'no-padding',
      data: { 'tagId': tagId }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
      });
  }
  sendComponentForApproval(comp: any) {
    comp.isReadyForApproval = true;
    comp['serviceEstimateComponentUrl'] = window.location.href;
    this.psService.sendServiceEstimateComponentForApproval(comp)
      .subscribe(res => {
        this.refreshServiceEstimateComponents();
      });
  }
  sendCOComponentForApproval(comp: any) {
    comp.isReadyForApproval = true;
    this.psService.sendChangeOrderComponentForApproval(comp)
      .subscribe(res => {
        this.refreshServiceEstimateComponents();
      });
  }

  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, true);
  }

  //cloneRecord(id: string) {
  //  this.psService.cloneSE(id)
  //    .subscribe(res => {
  //      this.refreshServiceEstimateCompoentsn();

  //    });
  //}
  approveSe() {
    if (this.seForm.valid) {
      let formValue = this.seForm.value;
      formValue.serviceEstimateCurrencies = formValue.serviceEstimateCurrencies.filter((x: any) => x.currencyConversionRateId != null);
      this.psService.approveSe(this.serviceEstimateId, formValue)
        .subscribe(res => {
          this.navigateToProjDetails();
          this._snackBar.open("Your Approval was Successful.", "Dismiss", {
            duration: 5000,
          });
          this.fetchSeSecData();
          this.finishedProcessing();

        });
    }
  }
  finalApproveSe() {
    if (this.seForm.valid) {
      let formValue = this.seForm.value;
      formValue.serviceEstimateCurrencies = formValue.serviceEstimateCurrencies.filter((x: any) => x.currencyConversionRateId != null);
      this.psService.finalApproveSe(this.serviceEstimateId, formValue)
        .subscribe(res => {
          this.navigateToProjDetails();

          this._snackBar.open("Your Approval was Successful.", "Dismiss", {
            duration: 5000,
          });
          this.fetchSeSecData();
          this.finishedProcessing();


        });
    }
  }
  finalApprovalCheck() {
    this.psService.checkIfFinalApprover(this.serviceEstimateId)
      .subscribe(res => {
        var data = res;
        this.isFinalApprover = data.canApprove;
      })
  }

  navigateToProjDetails() {
    if (!this.isApprovalPendingArea)
      if (this.serviceEstimateId)
        this.router.navigate(['../../'], { relativeTo: this.route.parent });
      else
        this.router.navigate(['../'], { relativeTo: this.route });
    else
      this.router.navigate(['../../../'], { relativeTo: this.route.parent });
  }

  exportActivities() {
    this.psService.downloanComponentActivities(this.serviceEstimateId)
      .subscribe(res => {
        if (res.length > 0) {
          const fileText = this.psService.convertToCsv(JSON.stringify(res));
          const blob = new Blob([fileText], {
            type: 'text/csv'
          });
          const url = window.URL.createObjectURL(blob);
          const link = this.donwloadCsvLink.nativeElement;
          link.href = url;
          link.download = 'ServiceEstimateComponentActiv.csv';
          link.click();
          window.URL.revokeObjectURL(url);
          this.isReportError = false;
        } else {
          this.isReportError = true;
        }

      },
        (error) => {

          this.isReportError = true;
        });
  }
  getCurrenctCurrencyCode() {
    return this.selectedCurrency ? this.selectedCurrency.isoCode + " " + this.selectedCurrency.symbol : "";
  }
  getServiceEstimateType() {
    switch (this.seForm.controls['serviceEstimateType'].value) {
      case 0: return "Fixed Fee";
        break;
      case 1: return "Time & Materials";
        break;
      case 2: return "Misc";
        break;
      default: return null;
    }
  }
  getCurrencyCode(item: UntypedFormGroup) {
    let code = item.controls['currencyCode'].value;
    let currency = this.currencies?.find(x => x.isoCode == code);
    return currency ? currency.currencyName + " " + currency.symbol : null;
  }
  getSecBpItems() {
    return this.serviceEstimateId ? (this.serviceEstimateType == 0 ? this.serviceEstimateComponents.filter(x => x.isAttached && x.businessPartnerItems.some((x: any) => !x.isFixedCost)) : this.serviceEstimateComponents.filter(x => x.isAttached && x.businessPartnerItems.some((x: any) => x.isFixedCost))) : [];
  }
  checkSecBpItemEstimatedHrs(id: string) {
    let secs = this.getSecBpItems();
    return secs.findIndex(x => x.id == id) >= 0;
  }
}
export function stepValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value !== undefined) {
      let rem = control.value && Number.parseFloat(control.value) % 0.25;
      if (rem) {
        return {
          'stepCount': {
            'stepCount': true, 'message': 'Please enter the valid value, only an increment of .25 allowed'
          }
        };
      }
      else
        return null;
    }
    return null;
  }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

<div class="row">

  <div class="col-md-12">


    <form *ngIf="user && seForm;" #form="ngForm" [formGroup]="seForm">
      <mat-toolbar color="accent">
        <span>
          <button title="Save/Update Service Estimate" mat-icon-button [disabled]="isLocked" type="submit"
                  [disabled]="!seForm.valid || seForm.controls['serviceEstimateStatus'].value == 4"
                  (click)="submitServiceEstimate()" [ngClass]="!form.submitted ? '' : 'submitted'"
                  id='serviceEstimateSaveBtn'>
            <mat-icon>save</mat-icon>
          </button>
          <button id = "mainSendBtn" mat-icon-button [disabled]="isLocked" color="default" type="button"
                  *ngIf="seForm.controls['isReadyForApproval'].value == false && serviceEstimateId && !isLocked"
                  (click)="sendForFinalApproval()" title="Send For Approval" [disabled]="!seForm.valid || isLocked || getSecBpItems().length > 0">
            <mat-icon>send</mat-icon>
          </button>
          <button mat-icon-button [disabled]="isLocked" type="button" [disabled]="!seForm.valid || getSecBpItems().length > 0"
                  *ngIf="(isProjectManagerOfficer || (isDirector && seForm.controls['serviceEstimateStatus'].value == 8)) && !isFinalApprover && !seForm.controls['isApproved'].value && seForm.controls['isMoreApprovalNeeded'].value"
                  (click)="approveSe()" title="Approve Service Estimate">
            <mat-icon>done</mat-icon>
          </button>
          <button mat-icon-button [disabled]="isLocked" type="button" [disabled]="!seForm.valid || getSecBpItems().length > 0"
                  *ngIf="isFinalApprover && !seForm.controls['isApproved'].value" (click)="finalApproveSe()"
                  title="Final Approve Service Estimate">
            <mat-icon>done</mat-icon>
          </button>
        </span>
        <span *ngIf="serviceEstimateId" class="flex-wrap">
          <span *ngFor="let item of tagItems.controls;let i = index">
            <button type="button" mat-raised-button (click)="openTagPopup(tagItems.controls[i].value.tagId)" [ngClass]="isRemoveBtnDisabled? 'no-click' : ''" [disabled]="isRemoveBtnDisabled">
              {{getTagName(tagItems.controls[i].value.tagId)}}
              <mat-icon [ngClass]="isLocked? 'no-click' : ''" class="md-18 cursor" (click)="removeTagItem(i, $event)">
                clear
              </mat-icon>
            </button>
          </span>
          <span (clickOutside)="toggleSelectOpened()" appClickOutside (keydown.Tab)="toggleSelectOpened()" *ngIf="!isLocked">
            <button mat-icon-button matTooltip="Add Tag" (click)="toggleSelect()" type="button" [ngStyle]="{display: isSelectOpened? 'none' : ''}" [disabled]="tagItems.value.length == 5" class="remove-align icon-ht">
              <mat-icon>add</mat-icon>
            </button>
            <app-tag *ngIf="tags" (tagSelected)="tagSelected($event)" [actualTags]="actualTags" [isSelectOpened]="isSelectOpened" [tagItems]="tagItems" #tag></app-tag>
          </span>
        </span>
        <span class="fill-remaining-space"></span>
        <span>
          <button matTooltip="Service Estimate Tools" type="button" mat-icon-button [matMenuTriggerFor]="menu"
                  aria-label="Service Estimate">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item type="button" (click)="exportActivities()">
              <mat-icon>cloud_download</mat-icon>
              <span class="menu-text">Export Activities</span>
            </button>
          </mat-menu>
        </span>
      </mat-toolbar>

      <fieldset [disabled]="isLocked">
        <ng-template #loading>
          <mat-spinner style="text-align: center;margin: 0 auto;"></mat-spinner>
        </ng-template>
        <mat-card class="top-level">
          <div *ngIf="!seForm.valid">
            <p class="red">The form is missing required fields</p>
          </div>
          <div *ngIf="isReportError">
            <p class="red">Problem Generating Report</p>
          </div>

          <p *ngIf="noComponentsAttachedAndApproved" class="red">No components are attached and approved. Please send
            components for
            approval before sending the Service Estimate for approval</p>
          <p *ngIf="isNotValidGrossMarginPerct" class="red">
            This SE Contains Non-Standard Pricing for BP Gross Margin%
          </p>
          <p *ngIf="!isLocked && getSecBpItems().length > 0" class="red" id="bpAlert">
            Alert: BP Items in the Component Section need to be Updated
          </p>
          <mat-card-content>
            <h1>Update/Create Service Estimate for {{projectName}} </h1>

            <mat-divider></mat-divider>
            <a *ngIf="serviceEstimateId" class="top-gap" mat-raised-button color="accent"
              [routerLink]="['seDetails']">Components</a>
            <div>
              <div class="col-md-4">
                <mat-form-field>
                  <input id = "seName" matInput placeholder="SE Name"
                         matTooltip="Enter a short name to describe the work being performed" formControlName="name"
                         required />
                  <mat-error *ngIf="seForm.controls['name'].hasError('required')">A name is required.</mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="isDirector || isAdmin" class="col-md-4">
                <mat-form-field>
                  <input matInput disabled readonly [matDatepicker]="contractSignedDate"
                         placeholder="Contract Signed Date" formControlName="contractSignedDate" />
                  <mat-datepicker-toggle matSuffix [for]="contractSignedDate"></mat-datepicker-toggle>
                  <mat-datepicker #contractSignedDate></mat-datepicker>
                  <!-- <input matInput type="text" placeholder="Prevailing Wage State" /> -->
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-select id="fixedFee" *ngIf="!isLocked" placeholder="Select Service"
                              disableOptionCentering formControlName="serviceEstimateType">
                    <mat-option [value]="0">Fixed Fee</mat-option>
                    <mat-option [value]="1">Time & Materials</mat-option>
                    <mat-option [value]="3" disabled >Managed Services</mat-option>
                    <mat-option [value]="2" disabled >Misc</mat-option>
                  </mat-select>
                  <input id="fixedFee" *ngIf="isLocked" [disabled]="isLocked" matInput placeholder="Select Service *" [value]="getServiceEstimateType()" />
                  <!-- <input matInput type="text" placeholder="Prevailing Wage State" /> -->
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <div matTooltip="This is a read-only field that will auto-populate">
                    <input matInput readonly disabled [matDatepicker]="dateAgreementSent" placeholder="Expiration Date"
                           formControlName="expirationDate" />
                  </div>
                  <mat-datepicker-toggle disabled matSuffix [for]="dateAgreementSent"></mat-datepicker-toggle>
                  <mat-datepicker #dateAgreementSent></mat-datepicker>
                  <div class="custom-error">
                    Expiration date is 30 days post the Approval of Service Estimate
                  </div>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input matInput placeholder="Version" formControlName="version" type="number" />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <div [formArrayName]="'serviceEstimateCurrencies'">
                  <div *ngFor="let item of serviceEstimateCurrenyItems.controls;let i = index" [formGroupName]="i">
                    <mat-form-field>
                      <mat-select *ngIf="!isLocked" placeholder="Select Foreign Currency" (selectionChange)="currencyChange($event, i)"
                                  disableOptionCentering formControlName="currencyCode" [id]="'currency'+(i+1)">
                        <mat-option *ngFor="let currency of currencies" [value]="currency.isoCode">
                          {{currency.currencyName + " " + currency.symbol}}
                        </mat-option>
                      </mat-select>
                      <input [id]="'currency'+(i+1)" *ngIf="isLocked" [disabled]="isLocked" matInput placeholder="Select Foreign Currency" [value]="getCurrencyCode(item)" />
                    </mat-form-field>
                  </div>
                  </div>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input matInput min="0" placeholder="Enter Milestone" type="number" formControlName="milestone" />
                  <mat-error *ngIf="seForm.controls['milestone'].hasError('pattern')">
                    Only integer numbers are allowed
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- <div>
              <h4 class="gray-text">Engineer Status Meetings (Starting November 2, meeting time should be added at the
                planning phase in the component level)</h4>
              <mat-divider></mat-divider>
              <h4 class="gray-text">Click below to add a resource</h4>
              <button mat-icon-button type="button" (click)="addResourceItem()" title="Add Project Resource">
                <mat-icon>add</mat-icon>
              </button>
              <input type="hidden" readonly placeholder="Number of Resources" [formControl]="resourceFormControl"
                autocomplete="false" />
              <div class="statusMeetingSection row" *ngIf="estimateResources" [formArrayName]="'estimateResources'">
                <div class="col-md-4">

                <mat-form-field>
                  <input matInput type="number" placeholder="Estimated Weeks of Project" autocomplete="false"
                    formControlName="estimatedWeeks" required />

                  <mat-error *ngIf="seForm.controls['estimatedWeeks'].hasError('required')">Estimatied weeks is required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="number" placeholder="Status meeting duration each Week by hour"
                    autocomplete="false" formControlName="statusMeetingDuration" required />

                  <mat-error *ngIf="seForm.controls['statusMeetingDuration'].hasError('required')">A Duration is required.
                  </mat-error>
                </mat-form-field>
              </div>
                <div class="col-md-4" *ngFor="let item of estimateResources.controls;let i = index" [formGroupName]="i">
                  <button mat-icon-button type="button" (click)="removeResourceItem(i)">
                    <mat-icon>clear</mat-icon>
                  </button>
                  <div>
                    <mat-form-field>
                      <mat-select [disabled]='isLocked' placeholder="Select a engineer role" disableOptionCentering
                        formControlName="serviceRoleId">
                        <mat-option *ngFor="let serviceRole of serviceRoles" [value]="serviceRole.serviceRoleId">
                          {{serviceRole.serviceRoleName + '(' + serviceRole.serviceRoleDescription + ')'}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$ &nbsp;</span>
                      <input matInput type="number" [placeholder]="'Resource ' + (i + 1) + ' Bill Rate'"
                        formControlName="payRate" readonly />
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$ &nbsp;</span>
                      <input matInput type="number" [placeholder]="'Resource ' + (i + 1) + ' Override Bill Rate'"
                        formControlName="overrideRate" />
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="number" [placeholder]="'Estimated Number of Meetings'" required
                        formControlName="estimatedWeeks" />
                      <mat-error *ngIf="estimateResources.at(i).hasError('required', ['estimatedWeeks'])">
                        Estimated Number of Meetings required</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="text" placeholder="Estimated Duration of Each Meeting" required
                        formControlName="estimatedDuration" />
                      <mat-error *ngIf="estimateResources.at(i).hasError('required', ['estimatedDuration'])">
                        Estimated Duration of Each Meeting required</mat-error>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </div> -->
            <h2>Components</h2>
            <mat-divider></mat-divider>
            <div class="col-md-12">
              <div>
                <ng-template #noComponentsArea>
                  <p>You must create a Service Estimate in order to add components.</p>
                </ng-template>
                <mat-list *ngIf="serviceEstimateComponents && azureAdId;else noComponentsArea">
                  <mat-list-item *ngFor="let comp of serviceEstimateComponents" [class.strikethrough]="comp.isRejected"
                    [class.red-text]="comp.isRejected" [class.yellow-text]="comp.isReadyForApproval && !comp.isApproved"
                    [class.green-text]="comp.isApproved" [class.blue-text]="!isLocked && checkSecBpItemEstimatedHrs(comp.id)">
                    <span mat-list-text>{{comp.componentName}}</span>
                    <button id = "addBtn" mat-mini-fab class="forceRight" color="default" [disabled]="isProcessing || comp.isRejected"
                      *ngIf="!comp.isAttached && (comp.createdBy.azureAdId == azureAdId || isDirector)" type="button"
                      (click)="attachServiceEstimateComponent(comp.id)" title="Attach Component">
                      <mat-icon>add</mat-icon>
                    </button>
                    <button mat-mini-fab class="forceRight" color="default" [disabled]="isProcessing" id="removeBtn"
                      *ngIf="comp.isAttached && (comp.createdBy.azureAdId == azureAdId || isDirector)"
                      (click)="detachServiceEstimateComponent(comp.id)" type="button" title="Remove Component">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <button id = "sendBtn" mat-mini-fab class="forceRight" color="default"
                      *ngIf="!comp.isApproved && !comp.isReadyForApproval && comp.isAttached && displayApprovalIcon(comp)"
                      title="Send For Approval" type="button" (click)="sendComponentForApproval(comp)">
                      <mat-icon>send</mat-icon>
                    </button>
                    <button id = "doneBtn" mat-mini-fab class="forceRight" color="default" [disabled]="comp.isApproved"
                            *ngIf="(comp.isReadyForApproval && comp.canUserApprove && !comp.isApproved ) || canBeApproved(comp) || (isAdmin && comp.isReadyForApproval && !comp.isApproved)"
                            title="Approve Component" (click)="approveComponent(comp)" type="button">
                      <mat-icon>done</mat-icon>
                    </button>
                    <button mat-mini-fab class="forceRight" color="default" [disabled]="comp.isApproved"
                      *ngIf="comp.isReadyForApproval && isDirector && !comp.isApproved" title="Reject Component"
                      (click)="rejectComponent(comp)" type="button">
                      <mat-icon>clear</mat-icon>
                    </button>
                    <a mat-mini-fab class="forceRight" title="View Details" color="default"
                      [routerLink]="['editComponent',comp.id]"
                      routerLinkActive="router-link-active">
                      <mat-icon>search</mat-icon>
                    </a>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pmArea">
                <h2>Project Management Cost</h2>
                <mat-divider></mat-divider>

                <div class="col-md-4" style="margin-top: 10px;">
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-4">
                      <mat-checkbox #pmOnlySE [value]="false" #excludePmInput
                        matTooltip="Only used for Project Management line of business" formControlName="isPMOnly">
                        PM Only SE?
                      </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input 
                          type="string" 
                          currencyMask 
                          matInput 
                          placeholder="Override Rate" 
                          [readonly]="!areComponentsAttached"
                          matTooltip="Please attach a component before entering an override rate" 
                          [matTooltipDisabled]="areComponentsAttached"
                          formControlName="masterPMOverrideRate" 
                        />
                      </mat-form-field>
                      <mat-error *ngIf="seForm.controls['masterPMOverrideRate'].errors?.min">
                        Please enter a valid value
                      </mat-error>
                      <mat-error *ngIf="seForm.get('masterPMOverrideRate')['errors']?.stepCount">
                        {{seForm.get('masterPMOverrideRate')['errors'].stepCount.message}}
                      </mat-error>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <h4>Calculated Rates</h4>

                  <mat-divider></mat-divider>
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="Hourly Rate" type="number"
                          formControlName="pMHourlyRate" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="Hours" type="number" formControlName="pMHours" />

                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="Percentage" formControlName="pMPercentage" />
                        <span matSuffix>%</span>
                      </mat-form-field>
                    </div>
                  </div>

                  <h4>Override Rates</h4>
                  <span>Override fields replace original values for calculations</span>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Hourly Rate" type="number" formControlName="pMOverrideHourlyRate" readonly
                          [errorStateMatcher]="matcher" id="pMOverrideHourlyRate" />
                        <mat-error *ngIf="seForm.controls['pMOverrideHourlyRate'].errors?.min">Please enter valid value
                        </mat-error>
                        <mat-error *ngIf="seForm.get('pMOverrideHourlyRate')['errors']?.stepCount">
                          {{seForm.get('pMOverrideHourlyRate')['errors'].stepCount.message}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class=" col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Hours" type="number" id="pMOverrideHours" formControlName="pMOverrideHours" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Percentage" type="number" min="0" id="pMOverridePercentage"
                               formControlName="pMOverridePercentage" />
                        <span matSuffix>%</span>
                        <mat-error *ngIf="seForm.controls['pMOverridePercentage'].hasError('pattern')">
                          Only integer numbers are allowed
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- <mat-error class="error-text"
                      *ngIf="!seForm.controls['pMOverrideHourlyRate'].errors?.min && seForm.controls['pMOverrideHourlyRate'].errors?.stepCount">
                      {{seForm.controls['pMOverrideHourlyRate'].errors?.stepCount}}
                    </mat-error> -->
                    <!-- <mat-error class="error-text" *ngIf="seForm.controls['pMOverrideHourlyRate'].errors?.min">
                      Please enter Valid value
                    </mat-error> -->
                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-12">
                      <mat-label>
                        <h4 class="display-label-text">Changed By:</h4>
                        <span>{{seForm.controls['pmChangedBy'].value}}</span>
                      </mat-label>
                    </div>
                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-2" style=" margin-top: 12%;">
                      <mat-label>
                        <h4 class="display-label-text">Notes:</h4>
                      </mat-label>
                    </div>
                    <div class="col-md-10">
                      <mat-form-field>
                        <textarea matInput placeholder="Reason for Override to be published" columns="10" rows="5"
                          formControlName="pmOverrideNotes"></textarea>
                        <mat-error *ngIf="seForm.controls['pmOverrideNotes'].hasError('required')">
                          PM Override Notes required
                        </mat-error>
                        <mat-error *ngIf="seForm.controls['pmOverrideNotes'].errors?.maxlength">
                          PM Override Notes should not exceed 500 characters
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <h4>Business Partner(BP) Calculated Rates</h4>

                  <mat-divider></mat-divider>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="BP PM Hourly Rate" type="number"
                          formControlName="bpPmHourlyRate" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="BP PM Hours" type="number" formControlName="bpPmHours" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="BP PM Percentage" formControlName="bpPmPercentage" />
                        <span matSuffix>%</span>
                      </mat-form-field>
                    </div>
                  </div>
                  <h4>Override Rates</h4>
                  <span>Override fields replace original values for calculations</span>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput type="number" readonly placeholder="Hourly Rate" [errorStateMatcher]="matcher" id="bpPmOverrideHourlyRate"
                          formControlName="bpPmOverrideHourlyRate" (blur)="calcPMOverride('bpPmOverrideHours', $event, true)" />
                        <mat-error *ngIf="seForm.get('bpPmOverrideHourlyRate')['errors']?.stepCount">
                          {{seForm.get('bpPmOverrideHourlyRate')['errors'].stepCount.message}}
                        </mat-error>
                        <mat-error class="error-text" *ngIf="seForm.controls['bpPmOverrideHourlyRate'].errors?.min">
                          Please enter Valid value
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Hours" id="bpPmOverrideHours" type="number" formControlName="bpPmOverrideHours" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput min="0" placeholder="Percentage"  id="bpPmOverridePercentage" type="number" formControlName="bpPmOverridePercentage" />
                        <span matSuffix>%</span>
                        <mat-error *ngIf="seForm.controls['bpPmOverridePercentage'].hasError('pattern')">
                          Only integer numbers are allowed
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-12">
                      <mat-label>
                        <h4 class="display-label-text">Changed By:</h4>
                        <span>{{seForm.controls['bppmChangedBy'].value}}</span>
                      </mat-label>
                    </div>
                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-2" style=" margin-top: 12%;">
                      <mat-label>
                        <h4 class="display-label-text">Notes:</h4>
                      </mat-label>
                    </div>
                    <div class="col-md-10">
                      <mat-form-field>
                        <textarea matInput placeholder="Reason for Override to be published" columns="10" rows="5"
                          formControlName="bppmOverrideNotes"></textarea>
                        <mat-error *ngIf="seForm.controls['bppmOverrideNotes'].hasError('required')">
                          BPPM Override Notes required
                        </mat-error>
                        <mat-error *ngIf="seForm.controls['bppmOverrideNotes'].errors?.maxlength">
                          BPPM Override Notes should not exceed 500 characters
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <h4>SKU Based Calculated Rates</h4>

                  <mat-divider></mat-divider>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="SKU Hourly Rate" type="number" id="skuHourlyRate"
                          formControlName="skuHourlyRate" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="SKU Hours" type="number" formControlName="skuHours" id="skuHours" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput readonly placeholder="SKU Percentage" formControlName="skuPercentage" id="skuPercentage" />
                        <span matSuffix>%</span>
                      </mat-form-field>
                    </div>
                  </div>
                  <h4>Override Rates</h4>
                  <span>Override fields replace original values for calculations</span>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput type="number" readonly placeholder="Hourly Rate" [errorStateMatcher]="matcher" id="skuOverrideHourlyRate"
                          formControlName="skuOverrideHourlyRate" (blur)="calcPMOverride('skuOverrideHours', $event, false, true)" />
                        <mat-error *ngIf="seForm.get('skuOverrideHourlyRate')['errors']?.stepCount">
                          {{seForm.get('skuOverrideHourlyRate')['errors'].stepCount.message}}
                        </mat-error>
                        <mat-error class="error-text" *ngIf="seForm.controls['skuOverrideHourlyRate'].errors?.min">
                          Please enter Valid value
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Hours" id="skuOverrideHours" type="number" formControlName="skuOverrideHours" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput min="0" placeholder="Percentage"  id="skuOverridePercentage" type="number" formControlName="skuOverridePercentage" />
                        <span matSuffix>%</span>
                        <mat-error *ngIf="seForm.controls['skuOverridePercentage'].hasError('pattern')">
                          Only integer numbers are allowed
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-12">
                      <mat-label>
                        <h4 class="display-label-text">Changed By:</h4>
                        <span>{{seForm.controls['skuChangedBy'].value}}</span>
                      </mat-label>
                    </div>
                  </div>
                  <div class="row" *ngIf="serviceEstimateId">
                    <div class="col-md-2" style=" margin-top: 12%;">
                      <mat-label>
                        <h4 class="display-label-text">Notes:</h4>
                      </mat-label>
                    </div>
                    <div class="col-md-10">
                      <mat-form-field>
                        <textarea matInput placeholder="Reason for Override to be published" columns="10" rows="5"
                          formControlName="skuOverrideNotes"></textarea>
                        <mat-error *ngIf="seForm.controls['skuOverrideNotes'].hasError('required')">
                          SKU Override Notes required
                        </mat-error>
                        <mat-error *ngIf="seForm.controls['skuOverrideNotes'].errors?.maxlength">
                          SKU Override Notes should not exceed 500 characters
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <h2>Totals</h2>
                <mat-divider></mat-divider>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12" style="margin-top: 10px;">
                  <mat-card-content>
                    <div class="col-md-12">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input matInput readonly currencyMask
                          placeholder="Business Partner Cost (not included total price)"
                          formControlName="businessCost" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input matInput readonly currencyMask placeholder="Business Partner Sell Price"
                          formControlName="bpSellAmount" />
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12" style="margin-top: 10px;">
                  <mat-card-content>
                    <div class="col-md-12">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input matInput readonly currencyMask
                          placeholder="SKU Cost" id="skuCost"
                          formControlName="skuCost" />
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12">
                  <mat-card-content>

                    <div class="col-md-12">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input matInput readonly currencyMask placeholder="Material Cost"
                          formControlName="materialCost" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field>
                        <span matPrefix>$&nbsp;</span>
                        <input matInput readonly currencyMask placeholder="Travel Cost" formControlName="travelCost" />
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12">
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask currencyMask placeholder="MIT PM Services Price"
                        formControlName="totalPmCost" />
                    </mat-form-field>
                  </div>
                </mat-card>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12">
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask placeholder="MIT Eng Services Price"
                        formControlName="servicesCost" />
                    </mat-form-field>
                  </div>
                </mat-card>
                <mat-card class="col-md-10 col-lg-8 col-xl-4 col-sm-12">
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matSuffix>Hours</span>
                      <input matInput readonly type="number" placeholder="MIT Service Hours(PM & Eng)"
                        formControlName="serviceHours" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask placeholder="Component Price Adjustments"
                        formControlName="priceAdjustments" />
                    </mat-form-field>
                  </div>
                </mat-card>

                <mat-card class="col-md-10 col-lg-8 col-xl-4 col-sm-12" *ngIf="serviceEstimateId">
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Service Estimate Price Adjustment"
                        formControlName="serviceEstimatePriceAdjustments" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field>
                      <textarea rows="15" matInput placeholder="Service Estimate Price Adjustments Note"
                        formControlName="serviceEstimatePriceAdjustmentsNote"></textarea>
                      <mat-error *ngIf="seForm.controls['serviceEstimatePriceAdjustmentsNote'].hasError('required')">
                        SE Price Adjustment Notes required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </mat-card>
                <mat-card class="col-md-10 col-lg-6 col-xl-4 col-sm-12">
                  <div class="col-md-12">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask placeholder="Estimated Total Price"
                        formControlName="estimatedPrice" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-12" *ngIf="serviceEstimateId && isScopeRiskEnabled">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask placeholder="Scope Risk Price"
                        formControlName="scopeRiskPrice" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-12" *ngIf="serviceEstimateId">
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput readonly currencyMask placeholder="Updated Estimated Total Price"
                        formControlName="updatedEstimatedTotalPrice" />
                    </mat-form-field>
                  </div>
                  <div [class.align-label]="selectedCurrency" class="col-md-12" *ngIf="serviceEstimateId">
                    <mat-label class="currency-label">Selected Foreign Currency Price - Estimated Total Price</mat-label>
                    <mat-form-field>
                      <span matPrefix>{{getCurrenctCurrencyCode()}}&nbsp;</span>
                      <input matInput readonly currencyMask
                             formControlName="currencyPrice" />
                    </mat-form-field>
                  </div>
                </mat-card>
                <mat-card class="col-md-10 col-lg-8 col-xl-4 col-sm-12" *ngIf="serviceEstimateId">
                  <div class="col-md-12">
                    <mat-form-field>
                      <textarea rows="15" matInput placeholder="Component Notes" readonly
                        formControlName="componentNotes"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field>
                      <textarea rows="15" matInput placeholder="Notes" formControlName="notes"></textarea>
                    </mat-form-field>
                  </div>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </fieldset>

    </form>
  </div>
</div>


<a #donwloadCsvLink></a>
